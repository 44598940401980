import React, { ChangeEventHandler, HTMLAttributes, useRef, useState } from 'react';
import InputWraper, { IInputWrapper } from '../InputWraper/InputWraper';
import s from './dropdown.module.scss';

interface IDropdown
    extends HTMLAttributes<HTMLDivElement>,
        Omit<IInputWrapper, 'isActive' | 'isFocused' | 'children' | 'onClick'> {
    emptyPlaceholder?: boolean;
    emptyFirstline?: boolean;
    placeholder?: string;
    value: string | number | { id: string | number };
    options: Array<{
        id: string | number;
        value: string;
    }>;
    onChange: (arg: any) => void;
    isValueObject?: boolean;
}

const Dropdown = ({
    isValueObject,
    options,
    placeholder,
    value,
    onChange,
    label,
    errorMessage,
    error,
    emptyPlaceholder,
    emptyFirstline,
    small,
    className,
    warningMessage,
}: IDropdown) => {
    const [isActive, setActive] = useState(false);
    const ref = useRef(null);

    const setInputFocus = () => {
        ref.current.focus();
        setActive(true);
    };

    const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
        if (isValueObject) {
            console.log(e.target.value);
            onChange(
                options.find((item) =>
                    typeof item.id === 'number' ? item.id === +e.target.value : item.id === e.target.value,
                ),
            );
        } else {
            onChange(e.target.value);
        }
    };

    return (
        <InputWraper
            label={label}
            small={small}
            warningMessage={warningMessage}
            className={className}
            error={error}
            errorMessage={errorMessage}
            isActive={isActive || !!value}
            isFocused={isActive}
            onClick={setInputFocus}
        >
            <select
                onChange={handleChange}
                onFocus={setInputFocus}
                onBlur={() => setActive(false)}
                ref={ref}
                defaultValue={isValueObject ? value?.id : value || undefined}
            >
                {emptyPlaceholder && <option defaultValue={''} hidden />}
                {placeholder && (
                    <option defaultValue={placeholder} hidden>
                        {placeholder}
                    </option>
                )}
                {emptyFirstline && <option value="" />}
                {options.map((item) => {
                    return (
                        <option className={s.option} key={item.id} value={item.id}>
                            {item.value}
                        </option>
                    );
                })}
            </select>
        </InputWraper>
    );
};

export default Dropdown;
