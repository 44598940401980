import React from 'react';
import logoSvg from './logo.svg';
import logoMini from './logo-mini.svg';

const Logo = ({ className, type }: { className?: string; type?: 'mini' }) => {
    const choseType = () => {
        switch (type) {
            case 'mini':
                return logoMini;
            default:
                return logoSvg;
        }
    };

    return <img className={className} src={choseType()} alt={''} />;
};

export default Logo;
