import React, { ReactNode, ReactElement, HTMLAttributes } from 'react';
import clsx from 'clsx';
import s from './gridRow.module.scss';

export enum GridJustifyEnum {
    LEFT = 'left',
    RIGHT = 'right',
    CENTER = 'center',
    AROUND = 'around',
    BETWEEN = 'between',
}

export enum GridAlignEnum {
    MIDDLE = 'middle',
    TOP = 'top',
    BOTTOm = 'bottom',
    STRETCH = 'stretch',
}

interface IGridRow extends HTMLAttributes<HTMLDivElement> {
    noWrap?: boolean;
    column?: boolean;
    justifyRow?: GridJustifyEnum;
    alignRow?: GridAlignEnum;
    gutter?: 4 | 8 | 12 | 16;
}

const GridRow = ({
    children,
    justifyRow,
    alignRow,
    noWrap,
    column,
    className,
    gutter,
    ...rest
}: IGridRow): ReactElement => {
    const style = clsx(
        s.item,
        justifyRow && s[`justify-${justifyRow}`],
        alignRow && s[`align-${alignRow}`],
        noWrap && s.noWrap,
        column && s.column,
        gutter && s[`gutter-${gutter}`],
        className,
    );

    return (
        <div className={style} {...rest}>
            {children}
        </div>
    );
};

export default GridRow;
