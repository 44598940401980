import React, { ReactElement, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import s from './body.module.scss';
import LoadingSpinner from '../Loading';
import InfoButton from './modules/infoButton';

interface IBody {
    title?: string;
    pageTitle?: string;
    children?: ReactNode;
    isLoading?: boolean;
    info?: string;
}

const Body = ({ title, pageTitle, children, isLoading, info }: IBody): ReactElement => {
    return (
        <div className={s.wrapper}>
            <Helmet>
                <title>
                    {'Текс-Колор Омск | '}
                    {pageTitle || title || 'Панель управления'}
                </title>
            </Helmet>
            {title && (
                    <h1 className={s.title}>
                        {title}
                        <InfoButton info={info} />
                    </h1>
            )}
            {isLoading ? <LoadingSpinner /> : <div className={s.content}>{children}</div>}
        </div>
    );
};

export default Body;
