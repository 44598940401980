import React, { HTMLAttributes } from 'react';
import s from './styles/card.module.scss';

interface ICard extends HTMLAttributes<HTMLDivElement> {}

const Card = ({ children }: ICard) => {
    return <div className={s.root}>{children}</div>;
};

export default Card;
