import React, { ReactNode } from 'react';
import s from './textItem.module.scss';
import clsx from 'clsx';

interface ITextItem {
    label: string;
    capitalize?: boolean;
    className?: string;
    children?: ReactNode;
    text?: string;
}

const TextItem = ({ capitalize, label, className, children, text }: ITextItem) => {
    return (
        <div className={className}>
            <div className={s.label}>{label}</div>
            <div className={s.text}>
                <div className={clsx(s.inline, capitalize && s.capitalize)}>
                    {children || text}
                    {!children && !text && '-'}
                </div>
            </div>
        </div>
    );
};

export default TextItem;
