import React, {useState} from 'react';
import {ButtonVariationEnum} from "../../../ui/Button/types";
import i18n from "../../../utils/i18n";
import Button from "../../../ui/Button";
import sCommon from '../../../utils/common.module.scss'
import Popup from "../../../ui/Popup";
import GridRow from "../../../ui/GridRow";
import {PopupSize} from "../../../ui/Popup/Popup";
import {FetchApi} from "../../../utils/fetch/fetchApi";
import {pathsApi} from "../../../router/paths";

const InvalidationButtons = () => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setSuccess] = useState<boolean>(false);
    const [isInvalidationPopupOpen, toggleInvalidationPopupOpen] = useState<boolean>(false)
    const [isForceCleanPopupOpen, toggleForceCleanPopupOpen] = useState<boolean>(false)

    const handleInvalidation = async () => {
        setLoading(true);
        const response = await FetchApi.get<{ success: boolean }>(pathsApi.cleanCache)
        setLoading(false)
        if (response?.success) {
            // TODO notification
            setSuccess(true);
        }
    }

    const handleForceAllCacheClean = async () => {
        setLoading(true);
        const response = await FetchApi.get<{ success: boolean }>(pathsApi.cleanCacheAll)
        setLoading(false)
        if (response?.success) {
            setSuccess(true);
        }
    }

    const handlePopupClose = () => {
        setSuccess(false)
        toggleInvalidationPopupOpen(false)
        toggleForceCleanPopupOpen(false)
    }

    return (
        <div className={sCommon.buttonWrapper}>
            <Button variation={ButtonVariationEnum.SOFT} onClick={() => toggleInvalidationPopupOpen(true)}>
                {i18n.cache.cleanCache}
            </Button>
            <Button variation={ButtonVariationEnum.ERROR} onClick={() => toggleForceCleanPopupOpen(true)}>
                {i18n.cache.cleanAllCache}
            </Button>
            {isInvalidationPopupOpen && <Popup
                size={PopupSize.xs}
                title={i18n.cache.cleanCache}
                close={handlePopupClose}
            >
                <GridRow gutter={8} column>
                <p>Сбрасывает кэш только измененных страниц. Используете только по завершении работы. НЕ используйте каждый раз при одном изменении.</p>
                    <Button variation={ButtonVariationEnum.SOFT} isLoading={isLoading} onClick={handleInvalidation}>
                        {i18n.cache.cleanCache}
                    </Button>
                    <Button onClick={handlePopupClose}>
                        {i18n.general.cancel}
                    </Button>
                </GridRow>
            </Popup>}
            {isForceCleanPopupOpen && <Popup
                size={PopupSize.xs}
                title={i18n.cache.cleanAllCache}
                close={handlePopupClose}
            >
                <GridRow gutter={8} column>
                    <p>Сбрасывает кэш всех страниц. Используйте только в экстренных случаях, т.к. увеличивает нагрузку на сервер и замедляет загрузку сайта для пользователей</p>
                    <Button variation={ButtonVariationEnum.SOFT} isLoading={isLoading} onClick={handleForceAllCacheClean}>
                        {i18n.cache.cleanAllCache}
                    </Button>
                    <Button onClick={handlePopupClose}>
                        {i18n.general.cancel}
                    </Button>
                </GridRow>
            </Popup>}
        </div>
    );
};

export default InvalidationButtons;