import React, { ReactNode } from 'react';
import s from './tag.module.scss';
import clsx from 'clsx';
import { TagEnum } from './types';

interface ITag {
    children: ReactNode;
    type?: TagEnum;
}

const Tag = ({ children, type }: ITag) => {
    const styles = clsx(s.root, type === TagEnum.GREEN && s.green, type === TagEnum.RED && s.red);

    return <div className={styles}>{children}</div>;
};

export default Tag;
