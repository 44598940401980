import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import s from './inputWraper.module.scss';
import clsx from 'clsx';
import InputMessage, { InputMessageTypes } from '../InputMessage/inputMessage';

export interface IInputWrapper {
    children: ReactNode;
    big?: boolean;
    label: string;
    error?: boolean;
    errorMessage?: string;
    onClick: () => void;
    className?: string;
    isActive?: boolean;
    isFocused?: boolean;
    small?: boolean;
    warningMessage?: string;
    infoMessage?: string;
}

const InputWraper = ({
    children,
    label,
    error,
    errorMessage,
    onClick,
    className,
    isActive,
    isFocused,
    small,
    big,
    warningMessage,
    infoMessage,
}: IInputWrapper) => {
    const styleWrapper = clsx(s.wrapper, className, small && s.small, big && s.big);

    const styleItem = clsx(
        s.item,
        isFocused && s['item--focused'],
        isActive && s['item--active'],
        error && s['item--error'],
    );

    return (
        <div className={styleWrapper} onClick={onClick}>
            <div className={styleItem}>
                {children}
                {label && !(small && isActive) && <span className={s.label}>{label}</span>}
            </div>
            {error && <InputMessage message={errorMessage} />}
            <InputMessage message={warningMessage} type={InputMessageTypes.WARNING} />
            <InputMessage message={infoMessage} type={InputMessageTypes.INFO} />
        </div>
    );
};

export default React.memo(InputWraper);
