import React, { ReactElement } from 'react';
import s from './inputMessage.module.scss';
import clsx from 'clsx';

export enum InputMessageTypes {
    ERROR,
    WARNING,
    INFO,
}

interface IComponentProps {
    type?: InputMessageTypes;
    message?: string;
    className?: string;
    noPadding?: boolean;
}

const InputMessage = ({
    type = InputMessageTypes.ERROR,
    message,
    className,
    noPadding,
}: IComponentProps): ReactElement | null => {
    if (!message) return null;
    return (
        <p
            className={clsx(
                s.message,
                type === InputMessageTypes.WARNING && s.warning,
                type === InputMessageTypes.INFO && s.info,
                noPadding && s.noPadding,
                className,
            )}
        >
            {message}
        </p>
    );
};

export default InputMessage;
