import React from 'react';
import Body from '../../ui/Body/body';
import i18n from '../../utils/i18n';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import { pathsApi } from '../../router/paths';
import { useFetchGet } from '../../utils/fetch/useFetchGet';
import { IPage } from '../../utils/types';
import { IInvalidateItem } from './types';
import Table from '../../ui/Table';
import InvalidationButtons from "./modules/invalidationButtons";

const Invalidation = () => {
    const { data, loadMore } = useFetchGet<IPage<IInvalidateItem>>({ path: pathsApi.invalidateAll, watchPage: true });

    return (
        <Body title={i18n.menu.cacheClean} isLoading={!data}>
            <GridRow gutter={8}>
                <GridCol col={12}>
                    <InvalidationButtons />
                </GridCol>
                <GridCol col={12}>
                    <Table
                        widths={[10, 40, 30]}
                        pagination={data && { current: data.current, total: data.total, loadMore }}
                        headers={[i18n.general.type, i18n.general.name, i18n.general.updated]}
                        data={data?.content.map((item) => ({
                            items: [item.type, item.name, item.updatedAt],
                            id: item.id
                        }))}
                    />
                </GridCol>
            </GridRow>
        </Body>
    );
};

export default Invalidation;
