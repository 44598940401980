import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import s from './popup.module.scss';
import Icon from '../Icon/Icon';
import clsx from 'clsx';

export enum PopupSize {
    xs = 2,
    s = 0,
    m = 1,
    l = 3,
}

interface IPopup {
    children: ReactNode;
    close: () => void;
    title?: string;
    classWrapper?: string;
    isBlur?: boolean;
    size?: PopupSize;
}

const Popup = ({ children, close, title, classWrapper, isBlur, size }: IPopup) => {
    const handleEscButton = (e) => {
        if (e.key === 'Escape') {
            close();
        }
    };

    useEffect(() => {
        document.body.classList.add(s.body);
        document.addEventListener('keyup', handleEscButton);
        return () => {
            document.body.classList.remove(s.body);
            document.removeEventListener('keyup', handleEscButton);
        };
    }, []);

    return ReactDOM.createPortal(
        <div className={clsx(s.wrapper, isBlur && s.wrapperBlur)}>
            <div className={s.close} onClick={close} />
            <div
                className={clsx(
                    s.window,
                    size === PopupSize.xs && s.sizeXS,
                    size === PopupSize.s && s.sizeS,
                    size === PopupSize.m && s.sizeM,
                    size === PopupSize.l && s.sizeL,
                    classWrapper,
                )}
            >
                <div className={s.header}>
                    <p>{title || null}</p>
                    {close && (
                        <div onClick={close}>
                            <Icon icon="close" className={s.closeIcon} autoFocus />
                        </div>
                    )}
                </div>
                {children}
            </div>
        </div>,
        // @ts-ignore
        document.getElementById('window'),
    );
};

export default Popup;
