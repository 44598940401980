export enum SpecIconEnum {
    wet = 'wet',
    fire = 'fire',
    strong = 'strong',
    winter = 'winter',
    speed = 'speed',
    noise = 'noise',
}

export const specIconOptions = [
    {
        id: SpecIconEnum.wet,
        value: 'Влагостойкий',
    },
    {
        id: SpecIconEnum.fire,
        value: 'Огнестойкий',
    },
    {
        id: SpecIconEnum.strong,
        value: 'Сверхпрочный',
    },
    {
        id: SpecIconEnum.noise,
        value: 'Звукоизоляционный',
    },
    {
        id: SpecIconEnum.speed,
        value: 'Моментальный',
    },
    {
        id: SpecIconEnum.winter,
        value: 'Зимний материал',
    },
];

export const findValue = (id: SpecIconEnum) => {
    return specIconOptions.find((icon) => icon.id === id)?.value || id;
};
