import React from 'react';
import Icon, { IconEnum } from '../Icon';
import classes from './styles/loading.module.scss';

const Loading = () => {
    return (
        <div className={classes.wrapper}>
            <Icon icon={IconEnum.LOADING} className={classes.loader} />
            <div className={classes.loaderDsk} />
        </div>
    );
};

export default Loading;
