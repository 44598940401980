import React, { HTMLAttributes } from 'react';
import s from './styles/dottedButton.module.scss';
import clsx from 'clsx';

interface IDottedButton extends HTMLAttributes<HTMLButtonElement> {}

const DottedButton = ({ children, className, ...rest }: IDottedButton) => {
    return (
        <button className={clsx(s.root, className)} {...rest}>
            {children}
        </button>
    );
};

export default DottedButton;
