import React, { ReactNode } from 'react';
import GridRow from '../GridRow';
import { NavLink } from 'react-router-dom';
import s from './styles/table.module.scss';
import clsx from 'clsx';
import LoadMore from './modules/LoadMore';

interface ITable {
    headers?: Array<string>;
    widths: Array<number | undefined>;
    data?: Array<{
        id: number;
        link?: string;
        items: Array<ReactNode>;
    }>;
    pagination?: {
        current: number;
        total: number;
        loadMore: () => void;
    };
}

const Table = ({ headers, widths, data, pagination }: ITable) => {
    return (
        <GridRow column className={s.root}>
            {headers && (
                <div className={clsx(s.header, s.row)}>
                    {headers.map((item, idx) => (
                        <div key={item} style={{ width: widths?.[idx] && widths[idx] + '%' }}>
                            {item}
                        </div>
                    ))}
                </div>
            )}
            {data?.map((row) => (
                row.link ? <NavLink to={row.link} className={clsx(s.row, s.rowItem)} key={row.id}>
                        {row.items.map((item, idx) => (
                            <div key={idx} style={{ width: widths?.[idx] && widths[idx] + '%' }}>
                                {item}
                            </div>
                        ))}
                    </NavLink> :
                    <div className={clsx(s.row, s.rowItem)} key={row.id}>
                    {row.items.map((item, idx) => (
                        <div key={idx} style={{ width: widths?.[idx] && widths[idx] + '%' }}>
                            {item}
                        </div>
                    ))}
                </div>
            ))}
            {pagination && <LoadMore {...pagination} />}
        </GridRow>
    );
};

export default Table;
