import { pathsApi } from '../../router/paths';
import { parse as parseCookie } from 'cookie'

const API_PREFIX = '/myapi';
const API_HOST = location.origin;
const X_CSRF_TOKEN = "x-csrf-token";
const X_CSRF_COOKIE = "csrftoken";

export const FetchApi = {
    get: async <T>(path: string): Promise<T | undefined> => {
        const response = await fetch(API_HOST + API_PREFIX + path, {
            method: 'GET',
            headers: {
                'Accept-Content': 'application/json',
            },
        });
        if (response.ok) {
            return await response.json();
        }
    },
    post: async <T>(path: string, body: any): Promise<T | undefined> => {
        const cookies = parseCookie(document.cookie);
        const response = await fetch(API_HOST + API_PREFIX + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Content': 'application/json',
                [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE]
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return await response.json();
        }
    },
    uploadFile: async <T>(body: any): Promise<T | undefined> => {
        const cookies = parseCookie(document.cookie);
        console.log(cookies);
        const response = await fetch(API_HOST + API_PREFIX + pathsApi.uploadFile, {
            method: 'POST',
            headers: {
                'Accept-Content': 'application/json',
                [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE]
            },
            body: body,
        });
        if (response.ok) {
            return await response.json();
        }
    },
    postLogin: async (path: string, body: any): Promise<Response | undefined> => {
        const cookies = parseCookie(document.cookie);
        const response = await fetch(API_HOST + API_PREFIX + path, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Content': 'application/json',
                [X_CSRF_TOKEN]: cookies[X_CSRF_COOKIE]
            },
            body: JSON.stringify(body),
        });
        if (response.ok) {
            return response;
        }
    },
};
