import React from 'react';
import { useFieldArray, Control, Controller, useFormState } from 'react-hook-form';
import i18n from '../../utils/i18n';
import { IconEnum } from '../../ui/Icon';
import Details from '../../ui/Details/details';
import s from './styles/priceBlock.module.scss';
import DottedButton from '../../ui/DottedButton';
import GridRow from '../../ui/GridRow';
import Input from '../../ui/Input';
import Dropdown from '../../ui/Dropdown';
import { priceUnits } from '../../utils/priceUnits';
import { priceMultiplier } from '../../utils/priceMultiplier';
import ToggleButton from '../../ui/ToggleButton/ToggleButton';
import GridCol from '../../ui/GridCol';
import Card from '../../ui/Card';
import SubValue from './module/subValue';
import Radio from '../../ui/Radio/Radio';
import { ButtonVariationEnum } from '../../ui/Button/types';
import Button from '../../ui/Button';
import PriceAttrBlock from '../PriceAttr';
import Attr from './module/attr';

interface IPriceBlock {
    name: string;
    control: Control;
}

const PriceBlock = ({ control, name }: IPriceBlock) => {
    const { errors } = useFormState({ control });
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    return (
        <Details label={i18n.product.priceList} icon={IconEnum.MONEY}>
            <GridRow gutter={8}>
                <GridCol col={12}>
                    <Controller
                        name={'individualPrice'}
                        control={control}
                        defaultValue={false}
                        render={({ field: { onChange, value } }) => (
                            <ToggleButton value={value} onClick={onChange} label={i18n.price.individualPrice} />
                        )}
                    />
                </GridCol>
                {fields?.map((item, idx) => {
                    return (
                        <GridCol key={idx} col={4}>
                            <Card>
                                <GridRow column gutter={8}>
                                    <Controller
                                        name={`${name}.${idx}.sku`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input
                                                value={value}
                                                onChange={onChange}
                                                type={'number'}
                                                label={i18n.price.sku}
                                                error={!!error}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.hidePrice`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { onChange, value } }) => (
                                            <ToggleButton
                                                value={value}
                                                onClick={onChange}
                                                label={i18n.price.hidePrice}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.mainValue`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input
                                                value={value}
                                                onChange={onChange}
                                                isCurrency
                                                type="number"
                                                label={i18n.price.mainPrice}
                                                error={!!error}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.mainUnits`}
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Dropdown
                                                value={value}
                                                emptyFirstline
                                                emptyPlaceholder
                                                onChange={onChange}
                                                options={priceUnits}
                                                error={!!error}
                                                isValueObject
                                                label={i18n.price.units}
                                            />
                                        )}
                                    />
                                    <SubValue control={control} name={`${name}.${idx}`} />
                                    <Controller
                                        name={`${name}.${idx}.subValue`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input
                                                value={value}
                                                onChange={onChange}
                                                type={'number'}
                                                label={i18n.price.subValue}
                                                error={!!error}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.multiplier`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Radio
                                                value={value}
                                                onClick={onChange}
                                                options={priceMultiplier}
                                                error={error?.message}
                                                label={i18n.price.multiplier}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.subUnits`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Dropdown
                                                value={value}
                                                emptyFirstline
                                                isValueObject
                                                onChange={onChange}
                                                options={priceUnits}
                                                error={!!error}
                                                label={i18n.price.subUnits}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name={`${name}.${idx}.extra`}
                                        control={control}
                                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                                            <Input
                                                value={value}
                                                onChange={onChange}
                                                label={i18n.price.extra}
                                                error={!!error}
                                            />
                                        )}
                                    />

                                    <Attr control={control} name={`${name}.${idx}`} />
                                    <Button
                                        isSmall
                                        variation={ButtonVariationEnum.OUTLINE}
                                        onClick={() => {
                                            remove(idx);
                                        }}
                                    >
                                        {i18n.general.delete}
                                    </Button>
                                </GridRow>
                            </Card>
                        </GridCol>
                    );
                })}
                <DottedButton className={s.addButton} onClick={() => append({})}>
                    {i18n.general.add}
                </DottedButton>
            </GridRow>
        </Details>
    );
};

export default PriceBlock;
