import { IProductFull } from '../../../utils/types';
import { priceUnits } from '../../../utils/priceUnits';

export const parseHistoryState = () => {
    const product: IProductFull = history.state?.usr?.product;
    if (!product) return;

    return {
        ...product,
        priceList: product.priceList?.map((price) => ({
            ...price,
            mainUnits: priceUnits.find((unit) => unit.value === price.mainUnits),
            subUnits: priceUnits.find((unit) => unit.value === price.subUnits),
        })),
        visible: !product.deletedAt,
        deletedAt: undefined,
        keywords: product.keywords?.split(','),
        techDescription: product.techDescription?.map((item) => {
            const [name, value] = item.split('::')
            return {
                name, value
            }
        })
    };
};
