export enum IconEnum {
    LOADING = 'load',
    USER = 'user',
    DOCS = 'docs',
    MONEY = 'money',
    SETTING = 'setting',
    SETTING2 = 'setting2',
    SEARCH = 'search',
    EDIT = 'edit',
    ARROW_RIGHT = 'arrow-right',
    IMAGE = 'img',
    FILTER = 'filter',
    QUESTION = 'how',
    DELETE = 'delete',
    BRAND = 'brand',
    CATALOG = 'catalog',
    DATABASE = 'db'

    // LOGOUT = 'logout',
    // DOC_OK = 'docOk',
    // DOC_SEARCH = 'docSearch',
    // DOC_NEW = 'docNew',
    // DOC_USER = 'docUser',
    // CLOSE = 'close',
    // OK = 'ok',
    // ERROR = 'error',
    // WARNING = 'warning',
    // OK_CHECK = 'ok-check',
    // CARD = 'card',
    // MINUS = 'minus',
    // USERS = 'users',
    // SHOP = 'shop',
    // CLOTH_DESCRIPTION = 'clothAttr',
    // CLOTH = 'cloth',
    // PRINT = 'print',
    // INFO = 'info'
}
