import React, { AnchorHTMLAttributes } from 'react';
import s from './styles/link.module.scss';
import clsx from 'clsx';
import { NavLink, NavLinkProps } from 'react-router-dom';

const Link = (props: NavLinkProps & AnchorHTMLAttributes<HTMLAnchorElement>) => {
    return <NavLink {...props} className={clsx(props.className, s.root)} />;
};

export default Link;
