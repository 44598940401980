import React, { useEffect } from 'react';
import i18n from '../../utils/i18n';
import Input from '../../ui/Input';
import { Controller, Control, useWatch, UseFormSetValue } from 'react-hook-form';
import { convertTextToUrl } from '../../utils/convertTextToUrl';

interface IAliasLink {
    control: Control;
    name: string;
    setValue: UseFormSetValue<any>;
}

const AliasLink = ({ control, setValue, name }: IAliasLink) => {
    const id = useWatch({ name: 'id', control });
    const brand = useWatch({ name: 'brand', control });
    const productName = useWatch({ name: 'name', control });

    useEffect(() => {
        if (id) return;
        if (brand || productName) {
            setValue(name, convertTextToUrl(`${brand ? brand.name + '-' : ''}${productName}`));
        }
    }, [brand, productName]);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: true, pattern: { message: i18n.general.wrongFormat, value: /[a-z0-9\-]+/ } }}
            defaultValue={''}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    errorMessage={error?.message}
                    label={i18n.general.link}
                    infoMessage={i18n.product.linkHint}
                />
            )}
        />
    );
};

export default AliasLink;
