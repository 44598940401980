import React, { ReactElement } from 'react';
import Icon from '../../Icon/Icon';
import s from '../body.module.scss';

interface IComponentsProps {
    info?: string;
}

const InfoButton = ({ info }: IComponentsProps): ReactElement | null => {
    if (!info) return null;

    return (
        <span className={s.infoWrapper}>
            <Icon className={s.info} icon="info" />
            <div className={s.tip}>
                <p>{info}</p>
            </div>
        </span>
    );
};

export default InfoButton;
