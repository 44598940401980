import React from 'react';
import s from './radio.module.scss';
import clsx from 'clsx';

type RadioObject = {
    id: number | string;
    value: string;
};
type RadioValues = string | number | RadioObject;

interface IRadio {
    label: string;
    options: Array<RadioObject>;
    value: RadioValues | Array<RadioValues>;
    multiple?: boolean;
    error?: string;
    column?: boolean;
    className?: string;
    onClick: (arg: RadioValues | Array<RadioValues> | undefined) => void;
    isValueObject?: boolean;
}

const Radio = ({ isValueObject, label, options, value, multiple, onClick, error, column, className }: IRadio) => {
    if (multiple && !Array.isArray(value)) {
        return null;
    }

    const handleClick = (itemObject: RadioValues, itemKey: string | number, isSelected: boolean) => {
        const valueToPass = isValueObject ? itemObject : itemKey;
        if (multiple) {
            onClick(
                isSelected
                    ? (value as Array<RadioValues>).filter(
                          (item) => (isValueObject ? (item as RadioObject).id : item) !== itemKey,
                      )
                    : (value as Array<RadioValues>).concat([valueToPass]),
            );
        } else {
            onClick(isSelected ? undefined : valueToPass);
        }
    };

    const checkIfSelected = (itemKey: string | number) => {
        if (multiple) {
            return isValueObject
                ? (value as Array<any>).some((item) => item.id === itemKey)
                : (value as Array<string | number>).includes(itemKey);
        }
        const valueId = isValueObject ? (value as RadioObject).id : value;
        return valueId === itemKey;
    };

    return (
        <div className={clsx(s.wrapper, className)}>
            {label && <p className={s.label}>{label}</p>}
            <div className={clsx(s.itemsWrapper, column && s.column)}>
                {options.map((item) => {
                    const isSelected = checkIfSelected(item.id);
                    return (
                        <div
                            key={item.id}
                            tabIndex={0}
                            className={clsx(s.item, isSelected && s['item--selected'], error && s['item--error'])}
                            onClick={() => handleClick(item, item.id, isSelected)}
                        >
                            {item.value}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Radio;
