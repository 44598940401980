'use client';

import React, { AnchorHTMLAttributes } from 'react';
import { NavLink } from 'react-router-dom';
import { IButtonCommon } from './types';
import { getStyles } from './utils/styles';

interface IButtonLink extends AnchorHTMLAttributes<HTMLAnchorElement>, IButtonCommon {
    href: string;
    prefetch?: boolean;
}

const ButtonLink = ({ children, className, href, isSmall, onClick, prefetch, variation, ...rest }: IButtonLink) => {
    // const startTransition = useLoader();
    const styles = getStyles({ className, isSmall, variation });

    return (
        <NavLink
            to={href}
            {...rest}
            onClick={(e) => {
                // startTransition();
                onClick?.(e);
            }}
            className={styles}
        >
            {children}
        </NavLink>
    );
};

export default ButtonLink;
