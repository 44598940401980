import React from 'react';
import clsx from 'clsx';
import s from './toggleButton.module.scss';
import GridRow, { GridAlignEnum } from '../GridRow/GridRow';

interface IToggleButton {
    className?: string;
    value: boolean;
    onClick: (arg: boolean) => void;
    label: string;
}

const ToggleButton = ({ value, onClick, className, label }: IToggleButton) => {
    return (
        <GridRow alignRow={GridAlignEnum.MIDDLE} className={clsx(s.wrapper, className)}>
            <div className={clsx(s.buttonWrapper, value && s['buttonWrapper--active'])} onClick={() => onClick(!value)}>
                <div className={s.child} />
            </div>
            <div>{label}</div>
        </GridRow>
    );
};

export default ToggleButton;
