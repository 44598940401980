export const richTextModules = {
    toolbar: [
        [{ header: [3, false] }],
        ['bold', 'italic', 'underline', { color: [] }], // toggled buttons
        ['link', 'image', 'video'],
        [{ list: 'ordered' }, { list: 'bullet' }],

        ['clean'], // remove formatting button
    ],
};
