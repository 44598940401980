import React, { ChangeEventHandler, useRef, useState } from 'react';
import i18n from '../../../utils/i18n';
import Popup from '../../../ui/Popup';
import GridRow from '../../../ui/GridRow';
import Button from '../../../ui/Button';
import TextItem from '../../../ui/TextItem/textItem';
import InputMessage, { InputMessageTypes } from '../../../ui/InputMessage/inputMessage';
import s from '../styles/imagesUpload.module.scss';
import { FileUploadType } from '../types';
import { FetchApi } from '../../../utils/fetch/fetchApi';
import { filesNames } from '../../../utils/fetch/filesNames';

interface IImageUploadPopUp {
    closePopUp: () => void;
    fileName?: string;
    type: FileUploadType;
    handleSuccess: (arg: string, size: number) => void;
    titleName: string;
}

const ImageUploadPopUp = ({ closePopUp, fileName, type, handleSuccess, titleName }: IImageUploadPopUp) => {
    const inputFieldRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);

    const handleSelectFile = () => {
        inputFieldRef.current?.click();
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const file = e.currentTarget.files?.[0];
        if (file) {
            setFile(file);
        }
    };

    const convertBytesToMb = (size?: number) => {
        if (size) {
            return (size / 1024 / 1024).toFixed(2) + ' МБ';
        }
    };

    const handleSave = async () => {
        if (!file) return;
        setLoading(true);

        const data = new FormData();
        data.append('file', file);
        data.append('fileName', fileName ? fileName + '.' + file.type.split('/')[1] : file.name);
        data.append('type', type);

        const result = await FetchApi.uploadFile<{ fileName: string }>(data);

        if (result) {
            handleSuccess(result.fileName, file.size);
            closePopUp();
        } else {
            setLoading(false);
        }
    };

    const acceptType = type === FileUploadType.DOC ? '.pdf,.doc' : 'image/*';

    return (
        <Popup close={closePopUp} title={i18n.images.upload + titleName}>
            <GridRow column gutter={8}>
                <input
                    type={'file'}
                    ref={inputFieldRef}
                    className={s.input}
                    accept={acceptType}
                    onChange={handleInputChange}
                />
                <Button onClick={handleSelectFile}>{i18n.images.choose + titleName}</Button>
                <TextItem label={i18n.images.fileName} text={file?.name} />
                <div>
                    <TextItem label={i18n.images.fileSize} text={convertBytesToMb(file?.size)} />
                    <InputMessage type={InputMessageTypes.INFO} message={i18n.images.fileSizeHint} />
                </div>
                <Button disabled={!file} isLoading={isLoading} onClick={handleSave}>
                    {i18n.general.save}
                </Button>
            </GridRow>
        </Popup>
    );
};

export default ImageUploadPopUp;
