import React, { ReactNode } from 'react';
import s from './styles/details.module.scss';
import Icon, { IconEnum } from '../Icon';
import clsx from 'clsx';

interface IDetails {
    label: string;
    children: ReactNode;
    icon?: IconEnum;
    error?: boolean;
}

const Details = ({ label, children, icon, error }: IDetails) => {
    const styles = clsx(s.root, error && s.error);

    return (
        <details className={styles}>
            <summary className={s.label}>
                {icon && <Icon icon={icon} className={s.icon} />}
                {label}
                <Icon icon={IconEnum.ARROW_RIGHT} className={s.toggleIcon} />
            </summary>
            {children}
        </details>
    );
};

export default Details;
