import { PriceMultiplierEnum } from './priceMultiplier';

export const getPriceSubCalculate = ({
    mainValue,
    subValue,
    multiplier,
}: {
    mainValue?: number;
    subValue?: number;
    multiplier?: PriceMultiplierEnum;
}) => {
    if (!(mainValue && subValue && multiplier)) return;
    return multiplier === PriceMultiplierEnum.DIVIDE ? mainValue / subValue : mainValue * subValue;
};
