import React from 'react';
import { Control, useWatch } from 'react-hook-form';
import TextItem from '../../../ui/TextItem/textItem';
import i18n from '../../../utils/i18n';
import { getPriceSubCalculate } from '../../../utils/priceSubCalculate';
import InputMessage, { InputMessageTypes } from '../../../ui/InputMessage/inputMessage';
import { getPrice } from '../../../utils/getPrice';

interface ISubValue {
    control: Control;
    name: string;
}

const SubValue = ({ control, name }: ISubValue) => {
    const mainValue = useWatch({ name: name + '.mainValue', control });
    const subValue = useWatch({ name: name + '.subValue', control });
    const multiplier = useWatch({ name: name + '.multiplier', control });
    const subUnits = useWatch({ name: name + '.subUnits', control });

    const price = getPrice(getPriceSubCalculate({ subValue, mainValue, multiplier })) || '-';

    return (
        <div>
            <TextItem label={i18n.price.subPriceTitle} text={`${price} ${subUnits?.value || ''}`} />
            <InputMessage type={InputMessageTypes.INFO} message={i18n.price.subValueHint} />
        </div>
    );
};

export default SubValue;
