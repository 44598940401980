import React, { ReactNode, ReactElement } from 'react';
import clsx from 'clsx';
import s from './gridCol.module.scss';

type TCol = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 12;

interface IGridColProps {
    align?: GridItemAlign;
    col?:
        | TCol
        | {
              xs?: TCol;
              md?: TCol;
              l?: TCol;
              xl?: TCol;
          };
    className?: string;
    children?: ReactNode;
}

export enum GridItemAlign {
    TOP,
}

const GridCol = ({ col, children, className, align, ...props }: IGridColProps): ReactElement => {
    const style = clsx(
        s.item,
        Number.isInteger(col) && s[`xs-${col}`],
        align === GridItemAlign.TOP && s.top,
        typeof col === 'object' && Object.entries(col).map((item) => s[`${item[0]}-${item[1]}`]),
        className,
    );

    return (
        <div {...props} className={style}>
            {children}
        </div>
    );
};

export default GridCol;
