export default {
    menu: {
        account: 'Аккаунт',
        products: 'Товары',
        productNew: 'Новый товар',
        brand: 'Производители',
        brandNew: 'Новый производитель',
        category: 'Категории',
        cacheClean: 'Очистка кэша',
        categoryNew: 'Новая категория',
    },
    general: {
        name: 'Название',
        brand: 'Бренд',
        category: 'Категория',
        open: 'Открыть',
        loadMore: 'Загрузить еще',
        link: 'Ссылка',
        wrongFormat: 'Не верный формат. Допускаются только латинские буквы, цифры и знак "-".',
        save: 'Сохранить',
        reset: 'Сбросить',
        delete: 'Удалить',
        add: 'Добавить',
        value: 'Значение',
        loading: 'Загрузка',
        edit: 'Изменить',
        duplicate: 'Клонировать',
        updated: 'Последние изменение',
        active: 'Активирован',
        filter: 'Фильтры',
        type: 'Тип',
        cancel: 'Отменить',
        order: 'Порядок сортировки'
    },
    images: {
        upload: 'Загрузить ',
        choose: 'Выбрать ',
        name: 'изборажение',
        fileName: 'Название выбранного файла',
        fileSize: 'Размеры выбранного файла',
        fileSizeHint: 'Максимальные размеры файла: 15МБ.',
        alt: 'Alt текст',
        title: 'Title текст',
        hintUsedForGoogle: 'Использяется для yandex/google.',
    },
    doc: {
        name: 'файл',
        title: 'Заголовок файла',
        techDescription: 'Техническая документация',
        fillNameAndBrand: 'Заполните поля "Название" и "Производитель" перед загрузкой файла',
    },
    product: {
        nameHint: 'Модель товара',
        subName: 'Под заголовок',
        subNameHint: 'Принадлежность товара к категории (тип товара), добавляется к имени',
        isVisible: 'Активирован (показывается на сайте)',
        specIcons: 'Иконка',
        imgZoom: 'Увеличение картинки',
        imageList: 'Изображения товара',
        description: 'Описание товара',
        techDescription: 'Технические хар-ки',
        howToUse: 'Как использовать',
        docList: 'Документация',
        priceList: 'Цены',
        linkHint: 'Заполняется автоматически',
        filters: 'Фильтры',
        zoomImg: 'Увеличение картинки',
        linkOpenProduct: 'Открыть продукт',
        updateProduct: 'Изменить товар',
    },
    seo: {
        name: 'SEO',
        keywords: 'Ключевые слова',
        keywordsHint: 'Используются для поиска товаров',
        metaTitle: 'Заголовок страницы',
        metaDescription: 'Описание страницы',
    },
    price: {
        sku: 'Артикул',
        mainPrice: 'Цена',
        units: 'Цена за',
        extra: 'Дополнительная информация',
        subValue: 'Коэф. дополнительной цены',
        subValueHint: 'Рассчитывается цену основываясь на основной цене',
        multiplier: 'Множитель дополнительной цены',
        subUnits: 'Дополнительная цена за',
        hidePrice: 'Скрыть цену',
        individualPrice: 'Цена рассчитывается индивидуально',
        subPriceTitle: 'Дополнительная цена:',
        attr: 'Аттрибуты',
        updateAttr: 'Изменить аттрибуты',
    },
    login: {
        name: 'Логин',
        password: 'Пароль',
        enter: 'Войти',
        error: 'Ошибка входа'
    },
    cache: {
        cleanCache: 'Очистить кэш',
        cleanAllCache: 'Сбросить весь кэш'
    }
};
