import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import GridRow from '../../ui/GridRow';
import GridCol from '../../ui/GridCol';
import Dropdown from '../../ui/Dropdown';
import { useConfig } from '../Config/config';
import Button from '../../ui/Button';
import { ButtonVariationEnum } from '../../ui/Button/types';
import i18n from '../../utils/i18n';
import s from './styles/priceAttr.module.scss';
import { IPriceAttr } from '../../utils/types';

interface IPriceAttrBlock {
    save: (arg: Array<IPriceAttr>) => void;
    initValue?: Array<IPriceAttr>;
}

const PriceAttrBlock = ({ initValue = [], save }: IPriceAttrBlock) => {
    const { priceAttrGroups } = useConfig();

    const defaultValues = initValue.reduce((acc, currentValue) => {
        const group = priceAttrGroups.find((group) => group.items.some((item) => item.id === currentValue.id));
        if (group) {
            return { ...acc, [group.id]: currentValue };
        }
        return acc;
    }, {});

    const { control, reset, handleSubmit } = useForm<any>({
        defaultValues,
    });

    const handleSave = handleSubmit((data) => {
        const result = Object.values(data).filter(Boolean) as Array<IPriceAttr>;
        save(result);
    });

    return (
        <GridRow gutter={4}>
            {priceAttrGroups.map((group) => {
                return (
                    <GridCol col={4} key={group.id}>
                        <Controller
                            name={`${group.id}`}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <Dropdown
                                    value={value}
                                    emptyFirstline
                                    isValueObject
                                    onChange={onChange}
                                    options={group.items}
                                    error={!!error}
                                    label={group.value}
                                />
                            )}
                        />
                    </GridCol>
                );
            })}
            <Button className={s.buttons} onClick={handleSave}>
                {i18n.general.save}
            </Button>
            <Button className={s.buttons} variation={ButtonVariationEnum.OUTLINE} onClick={reset}>
                {i18n.general.reset}
            </Button>
        </GridRow>
    );
};

export default PriceAttrBlock;
