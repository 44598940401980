import React, { useEffect, useRef, useState } from 'react';
import InputWraper, { IInputWrapper } from '../InputWraper/InputWraper';
import { onEnter } from '../../utils/onEnter';

interface IInput extends Omit<IInputWrapper, 'isActive' | 'isFocused' | 'children' | 'onClick'> {
    placeholder?: string;
    isCurrency?: boolean;
    value: string;
    type?: string;
    isMultiLine?: boolean;
    onChange: (arg: any) => void;
    disabled?: boolean;
    dateMin?: string;
    dateMax?: string;
    onKeyDown?: () => void;
    initActive?: boolean;
    onSubmit?: () => void;
    infoMessage?: string;
}

const Input = ({
    small,
    onChange,
    value,
    isCurrency,
    type = 'text',
    label,
    isMultiLine,
    error = false,
    errorMessage,
    disabled = false,
    placeholder,
    className,
    dateMin,
    dateMax,
    onKeyDown,
    initActive = false,
    onSubmit,
    warningMessage,
    infoMessage,
}: IInput) => {
    const [isActive, setActive] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        if (initActive) setInputFocus();
    }, []);

    const setInputFocus = () => {
        ref.current.focus();
        setActive(true);
    };

    const onKeyPress = (e) => {
        if (typeof onSubmit === 'function') {
            onEnter(e, onSubmit);
        } else if (typeof onKeyDown === 'function') {
            onKeyDown(e);
        }
    };

    return (
        <InputWraper
            label={label}
            error={error}
            errorMessage={errorMessage}
            onClick={setInputFocus}
            className={className}
            isActive={isActive || !!value || typeof value === 'number'}
            isFocused={isActive}
            infoMessage={infoMessage}
            small={small}
            warningMessage={warningMessage}
        >
            {isMultiLine ? (
                <textarea
                    ref={ref}
                    onChange={(e: any) => onChange(isCurrency ? e.target.value * 100 : e.target.value)}
                    value={isCurrency ? value / 100 : value}
                    onBlur={() => setActive(false)}
                    onFocus={setInputFocus}
                    onKeyDown={onKeyPress}
                    disabled={disabled}
                    rows={5}
                />
            ) : (
                <input
                    onChange={(e: any) => onChange(isCurrency ? e.target.value * 100 : e.target.value)}
                    value={isCurrency ? value / 100 : value}
                    type={type}
                    disabled={disabled}
                    step={isCurrency ? 'auto' : undefined}
                    placeholder={placeholder}
                    ref={ref}
                    onBlur={() => setActive(false)}
                    onFocus={setInputFocus}
                    min={dateMin}
                    max={dateMax}
                    onKeyDown={onKeyPress}
                />
            )}
        </InputWraper>
    );
};

export default Input;
