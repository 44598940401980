import React, { AnchorHTMLAttributes } from 'react';
import { getStyles } from './utils/styles';
import { IButtonCommon } from './types';

interface IButtonAnchor extends AnchorHTMLAttributes<HTMLAnchorElement>, IButtonCommon {}

const ButtonAnchor = ({ children, className, href, isSmall, variation, ...rest }: IButtonAnchor) => {
    const styles = getStyles({ className, isSmall, variation });

    return (
        <a href={href} {...rest} className={styles}>
            {children}
        </a>
    );
};

export default ButtonAnchor;
