export const priceUnits = [
    { id: 1, value: 'шт.' },
    { id: 2, value: 'л.' },
    { id: 3, value: 'кг.' },
    { id: 4, value: 'лист' },
    { id: 5, value: 'кв. м.' },
    { id: 6, value: 'куб. м.' },
    { id: 7, value: 'п. м.' },
    { id: 8, value: 'м.' },
    { id: 9, value: 'уп.' },
    { id: 10, value: 'мешок' },
    { id: 11, value: 'банка' },
    { id: 12, value: 'ведро' },
    { id: 13, value: 'рулон' },
    { id: 14, value: 'канистра' },
    { id: 15, value: 'плита' },
];
