import React, { useState, useEffect, ReactNode, createContext, useContext } from 'react';
import Loading from '../../ui/Loading';
import { FetchApi } from '../../utils/fetch/fetchApi';
import { pathsApi } from '../../router/paths';
import { IConfig } from './types';

const ConfigContext = createContext<IConfig>({} as IConfig);

const Config = ({ children }: { children?: ReactNode }) => {
    const [config, setConfig] = useState<IConfig | null>(null);

    const getConfig = async () => {
        const response = await FetchApi.get<IConfig>(pathsApi.config);
        if (response) {
            setConfig(response);
        }
    };

    useEffect(() => {
        getConfig();
    }, []);

    if (!config) return <Loading />;

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useConfig = () => useContext(ConfigContext);

export default Config;
