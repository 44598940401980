import React from 'react';
import { richTextModules } from './utils';
import ReactQuill from 'react-quill';
import s from './styles/richTextEditor.module.scss';
import InputMessage, { InputMessageTypes } from '../InputMessage/inputMessage';

interface IRichTextEditor {
    value: string;
    onChange: () => void;
    error?: string;
    info?: string;
    warning?: string;
}

const RichTextEditor = ({ value, onChange, error, info, warning }: IRichTextEditor) => {
    return (
        <div className={s.root}>
            <div className={s.wrapper}>
                <ReactQuill theme="snow" value={value} onChange={onChange} modules={richTextModules} />
            </div>
            <InputMessage message={error} />
            <InputMessage message={info} type={InputMessageTypes.INFO} />
            <InputMessage message={warning} type={InputMessageTypes.WARNING} />
        </div>
    );
};

export default RichTextEditor;
