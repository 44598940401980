import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { IButtonCommon } from './types';
import { getStyles } from './utils/styles';
import clsx from 'clsx';
import classes from './styles/button.module.scss';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement>, IButtonCommon {
    isLoading?: boolean;
}

const Button = ({ children, className, isLoading, isSmall, onClick, variation, ...rest }: IButton) => {
    const styles = clsx(getStyles({ className, isSmall, variation }), isLoading && classes.loading);

    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (isLoading) return;

        onClick?.(e);
    };

    return (
        <button className={styles} onClick={handleClick} {...rest}>
            {children}
        </button>
    );
};

export default Button;
