import React, { useState } from 'react';
import { ButtonVariationEnum } from '../../Button/types';
import i18n from '../../../utils/i18n';
import Button from '../../Button';
import s from '../styles/table.module.scss';

interface ILoadMore {
    current: number;
    total: number;
    loadMore: () => void;
}

const LoadMore = ({ current, loadMore, total }: ILoadMore) => {
    const [isTransition, startTransition] = useState<boolean>(false);

    if (total > current + 1) {
        return (
            <Button
                isSmall
                onClick={async () => {
                    startTransition(true);
                    await loadMore();
                    startTransition(false);
                }}
                isLoading={isTransition}
                className={s.loadMore}
                variation={ButtonVariationEnum.SOFT}
            >
                {i18n.general.loadMore}
            </Button>
        );
    }

    return null;
};

export default LoadMore;
