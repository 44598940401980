import clsx from 'clsx';
import { ButtonVariationEnum, IButtonCommon } from '../types';
import classes from '../styles/button.module.scss';

interface IButtonStyles extends IButtonCommon {
    className?: string;
}

export const getStyles = ({ className, isSmall, variation }: IButtonStyles) =>
    clsx(
        classes.main,
        variation === ButtonVariationEnum.OUTLINE && classes.outline,
        variation === ButtonVariationEnum.SOFT && classes.soft,
        variation === ButtonVariationEnum.SIMPLE && classes.simple,
        variation === ButtonVariationEnum.ERROR && classes.error,
        isSmall && classes.small,
        className,
    );
